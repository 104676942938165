import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle:
    "SpotOn Case Study | Blue Barn, Mamanoko, Padrecito, The Tipsy Pig",
  pageDescription:
    "Discover how 2 co-owners leveraged SpotOn to take business online and streamline operations for their 7 location during the pandemic.",
  title: '"Slow" food meets fast tech',
  subTitle: `How 2 San Francisco owners took 7 restaurants online with SpotOn.`,
  businessName: "Blue Barn, Mamanoko, Padrecito, The Tipsy Pig",
  industry: "Restaurant & hospitality, fast casual & casual dining",
  location: "San Francisco, California",
  products: [
    spotonProducts.RESTAURANT,
    spotonProducts.ORDER,
    spotonProducts.LOYALTY,
  ],
  description:
    "Restaurant owners Stryker Scales and Sam Josi operate several popular restaurants in San Francisco, ranging from fast casual to casual dining. With SpotOn technology, they've been able to meet the rising demands of their online customers and drastically reduce labor costs across all their location.",
  quoteData: {
    imgName: "blue-barn.png",
    quote: `“SpotOn Order has not only saved us money and improved our margins on the labor front, but it has also made things so much more streamlined and accurate in the kitchen. We have less errors and less waste. It's a huge improvement.”`,
    personName: "Sam Josi",
    personTitle: "Co-Owner, Blue Barn, Mamanoko, Padrecito, and The Tipsy Pig",
  },
  goal: `When the pandemic hit, Scales and Josi needed a restaurant POS that could quickly give them online ordering capabilities across their 4 concepts and 7 locations. They were also looking for a way to streamline operations so they could run with less staff.`,
  solution: `With SpotOn Order, Blue Barn had online ordering up and running in a week. And with a cloud-based system, Scales can now program all 7 restaurants from one place, doing 50% of his business through online ordering post-pandemic.`,
  solutionReadMore: {
    text: `Read their story.`,
    url: `https://spoton.com/blog/stryker-scales-sam-josi-running-restaurants-our-way/`,
  },
  results: {
    title: `The results`,
    stats: [
      { title: `11%`, description: `increase in ordering` },
      {
        title: `$3,600+`,
        description: `saved monthly in labor costs`,
      },
      { title: `20-minute`, description: `faster off-premise order times` },
    ],
  },
};

export const relatedSuccessStories = [
  // {
  //   title: "Making a fast-casual chain faster",
  //   imageName: "hale-and-hearty.png",
  //   industry: resourceIndustries.ENTERPRISE,
  //   tag: resourceTags.CASE_STUDY,
  //   date: "2022-03-24",
  //   href: "/case-studies/hale-and-hearty",
  //   goals: [
  //     resourceGoals.STREAMLINE_OPERATIONS,
  //     resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
  //     resourceGoals.CONNECT_AND_SELL_ONLINE,
  //   ],
  // },
  {
    title: "Hot pizza after cold ski days",
    imageName: "base-camp-pizza.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-09",
    href: "/case-studies/base-camp-pizza",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
  {
    title: "Steak and tech done to perfection",
    imageName: "deblaze-grill.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-07",
    href: "/case-studies/deblaze-grill",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Selling tacos, turning tables",
    imageName: "la-chiva-loka.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-14",
    href: "/case-studies/la-chiva-loka",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
];
